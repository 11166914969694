import type { ClickoutUrl } from '@/helpers/clickout-helper';

import { getVm } from '@/helpers/vm-helper';
import { FromExperiment, useExperiment } from '@/helpers/composables/useExperiment';
import { useUserAgent } from '@/helpers/composables/useUserAgent';
import { useLanguageStore, useUserStore } from '@/helpers/composables/useStores';
import { computed } from 'vue';

// ?preferred_variant=AMAZON_FREE_TRIAL_EXP:control
// ?preferred_variant=AMAZON_FREE_TRIAL_EXP:variant_1
// ?preferred_variant=AMAZON_FREE_TRIAL_EXP:variant_2
// ?remove_preferred_experiment=AMAZON_FREE_TRIAL_EXP

const PRIME_VIDEO_ID = 9 as const;
const ATV_PLUS_ID = 350 as const;

const config = {
	name: 'AMAZON_FREE_TRIAL_EXP',
	variants: {
		/** Control: No behavior change but adds tracking tags. */
		CONTROL: 'control',
		/** V1: Adds tracking tag + if the title detail page has an Amazon Prime offer and the device is not iOS, we show the Amazon Prime offer */
		VARIANT_1: 'variant_1',
		/** V2: Adds tracking tag + if the device is not iOS, we hide the Apple TV+ offer */
		VARIANT_2: 'variant_2',
	} as const,
};

export type AmazonFreeTrialExpVariants = 'control' | 'variant_1' | 'variant_2' | null;

let experiment: FromExperiment<typeof config> = null;

/**
 * Experiment to track prime video clicks and see our impact on starting streams for the
 * Amazon Prime Video Stream bounty program.
 */
export function useAmazonFreeTrialExp() {
	const { country } = useLanguageStore();
	const { deviceType, deviceOs } = useUserAgent();

	if (experiment === null) {
		experiment = useExperiment({
			...config,
			shouldTrigger: () => country.value === 'US',
		});
	}

	const { activeVariant, trackEvent } = experiment;

	const amazonFreeTrialExpActiveVariant = computed(() => activeVariant.value);

	const trackingTag = computed(() => {
		if (activeVariant.value == null) return '';

		const route = getVm().$route;

		const device = deviceType.value === 'desktop' ? 'des' : 'mob';

		const appleDeviceOs = deviceOs.value === 'ios' ? 'ios' : deviceOs.value === 'macos' ? 'mac' : null;

		// prettier-ignore
		const page =
			route.name == null ? '' :
            route.name === 'app.detail.movies' ? 'movie' :
            route.name === 'app.detail.show' ? 'show' :
            route.name === 'app.detail.show.season' ? 'season' :
            '';

		// prettier-ignore
		const variant =
			activeVariant.value === 'control'
				? 'c'
				: activeVariant.value === 'variant_1'
				? 'v1'
				: activeVariant.value === 'variant_2'
				? 'v2'
				: '';

		if (page === '' || variant === '') return '';

		return `just${appleDeviceOs ?? device}${page}us${variant}-20`;
	});

	function addTrackingTag(offerUrl: string, providerId: number) {
		const tagName = providerId === PRIME_VIDEO_ID ? 'tag' : providerId === ATV_PLUS_ID ? 'ct' : '';

		return addStringUrlTag(offerUrl, tagName, trackingTag.value);
	}

	function tagProviderFreeTrialUrl(url: ClickoutUrl, providerId: number) {
		if (
			activeVariant.value === null ||
			(url.providerId !== PRIME_VIDEO_ID && url.providerId !== ATV_PLUS_ID) ||
			trackingTag.value === '' ||
			country.value !== 'US'
		)
			return url;

		let offerUrl = url.get('r');
		if (offerUrl === null) return url;

		offerUrl = addTrackingTag(offerUrl, providerId);

		return url.set('r', offerUrl);
	}

	return { tagProviderFreeTrialUrl, amazonFreeTrialExpActiveVariant, trackEvent };
}

function addStringUrlTag(url: string, tag: string, value: string) {
	const u = new URL(url);
	u.searchParams.set(tag, value);

	return u.toString();
}
